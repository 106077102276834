@import url("colors.css");

.window {
  height: 100%;
  width: 100%;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.04);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.1px);
  -webkit-backdrop-filter: blur(8.1px);
  border: 1px solid rgba(255, 255, 255, 0.36);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.window-title {
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: transparent;
  color: var(--peach);
  position: sticky;
  border-bottom: 1px solid var(--surface1);
}

.window-title h1 {
  color: var(--peach);
  margin-left: auto;
  margin-right: auto;
  font-size: 1em;
}

.window-content {
  padding: 15px;
  color: var(--text);
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.window-content h1 {
  color: var(--teal);
}

/* Predefined sizes */
.window.small {
  min-width: 200px;
  min-height: 150px;
}

.window.medium {
  min-width: 400px;
  min-height: 300px;
}

.window.large {
  min-width: 600px;
  min-height: 450px;
}

/* Terminal appearance */

@media (max-width: 768px) {
  .window {
    margin: 10px;
    max-height: none; /* Allow the window to expand */
    overflow: auto; /* Adjust as necessary to avoid scrollbars */
  }
}
