@import url("colors.css");

.card {
  display: flex;
  flex-direction: column;
  width: calc(33.33% - 30px); /* Default width */
  margin: 20px;
  margin-bottom: 30px;
  padding: 15px;
  border: 2px solid var(--overlay2);
  border-radius: 10px;
  background-color: var(--base);
  color: var(--text);
  box-shadow: 0px 0px 62px 12px rgba(0, 0, 0, 0.79);
  box-sizing: border-box;
  transition:
    transform 0.2s ease,
    box-shadow 0.4s ease;
  position: relative;
  overflow: hidden; /* Prevent overflow artifacts */
  transform-style: preserve-3d; /* Enable 3D transformations */
  perspective: 1500px; /* Perspective for 3D depth */
}

.card:hover {
  box-shadow:
    10px 10px 25px 10px var(--red),
    -15px 10px 25px 10px var(--yellow),
    -10px -10px 25px 10px var(--green),
    20px -10px 25px 10px var(--blue),
    20px 15px 25px 15px var(--pink),
    -15px 15px 25px 15px var(--flamingo),
    -15px -10px 40px 5px var(--mauve),
    2px -6px 75px 35px rgba(0, 0, 0, 0);
  overflow: visible;
}

.card .project-title,
.card .project-picture,
.card .description-content,
.card .bottom-section {
  transition: transform 0.3s ease;
  transform-style: preserve-3d;
  position: relative;
}

.project-title {
  transform: translateZ(60px);
  text-align: center;
  font-size: 90%;
  margin-bottom: 15px;
  color: var(--peach);
}

.project-picture {
  width: 100%;
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
  padding: 3%;
  padding-bottom: 0px;
  transform: translateZ(110px);
}

.project-picture img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  border: 2px solid var(--overlay1);
  position: relative;
  z-index: 1;
}

.description-content {
  background-color: var(--surface0);
  color: var(--text);
  padding: 10px;
  margin: 6px;
  border-radius: 8px;
  border: 2px solid var(--overlay1);
  margin-bottom: 15px;
  height: 70px;
  overflow: hidden;
  position: relative;
  transform: translateZ(55px);
  z-index: 1;
}

.bottom-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
  margin-top: auto;
  transform: translateZ(20px);
}

.stack-icons {
  display: flex;
  gap: 8px;
}

.stack-icons img {
  width: 30px;
  height: 30px;
  transition: transform 0.3s ease;
}

.stack-icons img:hover {
  transform: translateY(-5px);
}

.project-links a {
  display: inline-block;
  color: var(--sapphire);
  text-decoration: none;
  padding: 8px;
  border: 2px solid var(--overlay1);
  border-radius: 8px;
  transition:
    background-color 0.3s ease,
    color 0.3s ease,
    transform 0.3s ease;
  font-size: 1.5em;
}

.project-links a:hover {
  background-color: var(--sapphire);
  color: var(--base);
  transform: translateY(-5px);
}

/* Media Queries for Mobile */
@media screen and (max-width: 990px) {
  .card {
    width: calc(100% - 20px); /* Full width on mobile */
  }
}
