@import url("components/colors.css");

@font-face {
  font-family: "Meslo-Regular";
  src:
    local("Meslo-Regular"),
    url("../public/assets/fonts/MesloLGLDZNerdFontMono-Regular.ttf")
      format("truetype");
}
@font-face {
  font-family: "Meslo-Italic";
  src:
    local("Meslo-Italic"),
    url("../public/assets/fonts/MesloLGLDZNerdFontMono-Italic.ttf")
      format("truetype");
}
@font-face {
  font-family: "Meslo-Bold";
  src:
    local("Meslo-Bold"),
    url("../public/assets/fonts/MesloLGLDZNerdFontMono-Bold.ttf")
      format("truetype");
}

@font-face {
  font-family: "Meslo-BoldItalic";
  src:
    local("Meslo-BoldItalic"),
    url("../public/assets/fonts/MesloLGLDZNerdFontMono-BoldItalic.ttf")
      format("truetype");
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Meslo-Regular";
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Prevents scrollbars from affecting layout */
  background-color: var(--base);
}

body {
  overflow-y: auto; /* Allows vertical scrolling */
}

::-webkit-scrollbar {
  width: 8px; /* Adjust width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: var(--crust); /* Track color */
}

::-webkit-scrollbar-thumb {
  background-color: var(--overlay1); /* Scrollbar color */
  border-radius: 10px; /* Roundness of the scrollbar */
  border: 2px solid var(--surface0); /* Padding around scrollbar */
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures it covers at least the viewport height */
  background-color: var(--base);
}

.main-content {
  flex: 1; /* Takes up remaining space in the flex container */
  width: 100%;
  background-color: var(--base);
}

@media screen and (max-width: 769px) {
  .main-content {
    height: auto;
    max-height: none;
  }
}
