@import url("../components/colors.css");

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 120px);
  position: relative;
  overflow: hidden;
}

.line {
  position: absolute;
  height: 4px;
  margin: 20px 0px;
}

.text h1 {
  font-size: 5em;
  font-family: "Meslo-Bold";
  text-shadow: 0px 0px 15px rgba(242, 205, 205, 0.5);
}
.text {
  font-size: 2em;
  text-align: center;
  color: var(--flamingo); /* Text color */
}

@media screen and (max-width: 769px) {
  .container {
    height: 70vh;
  }

  .text h1 {
    font-size: 2.5em;
  }
  .text {
    font-size: 1em;
  }
}

@media screen and (max-width: 480px) {
  .text {
    font-size: 1.1em;
  }

  .text h1 {
    font-size: 2em;
  }
}
