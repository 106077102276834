.project-page {
  padding: 20px;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.project-hero {
  font-size: 3em;
  align-content: center;
  justify-content: center;
  margin-bottom: 30px;
  color: #f2cdcd;
  height: 100px;
  width: 105vw;
  border: solid 2px #9399b2;
  border-radius: 5em;
  border-top: none;
  text-align: center;
}

.cards-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  padding-bottom: 20px;
}

@media screen and (max-width: 769px) {
  .cards-container {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
}
