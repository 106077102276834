@import url("../components/colors.css");

.contact-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: calc(100vh - 100px); /* Make the sections take full viewport height */
}

.contact-info {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Align children to the center */
  padding: 20px;
  width: 100%;
  height: auto;
}

.contact-title {
  font-size: 3em;
  color: var(--peach);
}

.contact-description {
  margin-top: 20px;
  font-size: 1.5em;
  color: var(--flamingo);
}

.contact-icons {
  display: flex;
  height: 100%;
  width: 100%;
  align-self: center;
  align-content: center;
  justify-content: space-evenly;
  flex-direction: row;
}

.socials {
  font-size: 10rem;
  color: var(--flamingo);
  align-content: center;
  align-self: center;
}

.icon-style {
  color: var(--flamingo);
  transition: 0.6s all ease;
  text-shadow: 0 0 30px rgba(88, 91, 112, 0.7);
}

.icon-style:hover {
  color: var(--mauve);
  text-shadow:
    0 0 10px rgba(203, 166, 247, 0.5),
    0 0 20px rgba(203, 166, 247, 0.5),
    0 0 30px rgba(203, 166, 247, 0.5),
    0 0 40px rgba(203, 166, 247, 0.5);
  transform: scale(1.1);
}

.contact-decoration {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

@media (max-width: 768px) {
  .contact-page {
    height: calc(100vh - 100px);
  }

  .contact-title {
    font-size: 3.5em;
  }

  .contact-description {
    font-size: 1.5em;
  }

  .socials {
    font-size: 8rem;
    margin: 0 10px;
  }
}

@media (max-width: 650px) {
  .contact-title {
    font-size: 3.2em;
  }

  .contact-description {
    font-size: 1em;
  }

  .socials {
    font-size: 5rem;
    margin: 0 10px;
  }
}
