@import url("../components/colors.css");

h1 {
  color: var(--peach);
  font-size: clamp(1.5rem, 3.5vw, 4rem);
}
h2 {
  color: var(--flamingo);
  font-size: clamp(1rem, 2vw, 3rem);
}
p {
  color: var(--text);
  font-size: clamp(1vw, 1rem, 1.4rem);
}
.whoami-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.hero-section {
  text-align: center;
  padding: 20px;
  background: rgba(255, 255, 255, 0.04);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.1px);
  -webkit-backdrop-filter: blur(8.1px);
  border: 1px solid rgba(255, 255, 255, 0.36);
  width: 100%; /* Make the hero section full width */
  max-width: 1200px; /* Add a max-width for better layout on large screens */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px; /* Reduce the gap between content and image */
  color: var(--text);
}

.hero-content-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px; /* Reduce the gap for better alignment */
}

@media screen and (max-width: 990px) {
  .hero-content-container {
    flex-direction: column;
    text-align: center; /* Center content on smaller screens */
  }
}

.hero-content {
  flex: 1;
  padding: 0 20px; /* Add padding for better spacing */
  text-align: left;
}

.hero-content p {
  color: var(--text);
  margin-top: 40px;
  font-size: clamp(1vw, 1rem, 1.4rem);
}

.hero-image {
  flex: 1;
  display: flex;
  justify-content: center;
}

.hero-image img {
  max-width: 80%; /* Adjust image size for better balance */
  height: auto;
  border-radius: 10px;
}

.about-me,
.skills-interests,
.contact {
  width: 100%; /* Ensure full width for all sections */
  max-width: 1200px; /* Add a max-width for consistency */
  margin: 2rem 0;
}

.skills-interests {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

@media screen and (max-width: 900px) {
  .skills-interests {
    flex-direction: column;
  }
}

.skills-interests > div {
  flex: 1; /* Ensure both skills and interests take equal width */
}

.skills-interests ul {
  list-style-type: none;
  padding: 0;
}

.skills-interests li {
  margin: 0.5rem 0;
}

.link {
  color: var(--peach);
  text-decoration: none;
}

.hobbies-list li strong {
  font-weight: bold;
  font-size: clamp(1rem, 1.2rem, 1.5rem);
  color: var(--peach);
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  grid-template-rows: repeat(4, auto); /* 4 rows */
  gap: 20px;
  justify-items: center;
  align-items: center;
  padding: 20px;
}

.grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 1s ease;
}

.grid-item:hover {
  transform: scale(1.3);
}
