@import url("colors.css");

.navbar {
  background: var(--base);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  border: 1px solid var(--flamingo);
  top: 1;
  z-index: 999;
  width: 100vw;
  box-shadow: 0px -5px 31px 10px rgba(242, 205, 205, 0.7);
  margin-bottom: 10px;
}

.navbar.slide-in {
  visibility: hidden;
  animation: slideIn 1.3s forwards 4.2s; /* 4.2s delay, 1s duration */
}

@keyframes slideIn {
  from {
    transform: translateY(-100%);
    opacity: 0;
    visibility: visible; /* Make it visible when the animation starts */
  }
  to {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
}
.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  transition:
    color 0.5s ease,
    text-shadow 0.5s ease;
}

.navbar-logo:hover {
  color: var(--peach);
  text-shadow: 0 0 15px rgba(250, 179, 135, 0.5);
}

.fa-terminal {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  transition:
    color 0.5s ease,
    text-shadow 0.5s ease;
}

.nav-links:hover {
  color: var(--peach);
  text-shadow: 0 0 15px rgba(250, 179, 135, 0.5);
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 769px) {
  .navbar {
    padding-left: 15px;
    padding-right: 15px;
    justify-content: space-between; /* Center the content on mobile */
  }

  .navbar-container {
    justify-content: space-between; /* Space out logo and menu icon */
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    justify-content: flex-start;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease-in;
  }

  .nav-menu.active {
    background: var(--base);
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
    transition: all 0.5s ease-in;
    z-index: 998;
  }

  .nav-item {
    width: 100%;
    text-align: center;
  }

  .nav-links {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: white;
    transition:
      color 0.5s ease,
      font-size 0.5s ease;
    font-size: 2rem;
    position: relative; /* Allows the ::after element to be positioned relative to .nav-links */
  }

  .nav-links::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    background-color: var(--flamingo);
    transition: width 0.5s ease;
  }

  .nav-links:hover {
    color: var(--peach);
    text-shadow: 0 0 15px rgba(250, 179, 135, 0.5);
    font-size: 2.5rem; /* Increase font size on hover */
  }

  .nav-links:hover::after {
    width: 100%; /* Expands the width of the ::after element to 100% on hover */
  }

  .navbar-logo {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .fa-bars {
    color: #fff;
  }

  .menu-icon {
    display: block;
    font-size: 1.8rem;
    cursor: pointer;
  }
}

@media screen and (min-width: 769px) {
  .navbar {
    border: 1px solid var(--flamingo);
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;

    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    transition: border-radius 0.3s;
  }
}
